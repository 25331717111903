<template>
    <div>
    <TitleAdmin msg="view rounds" routerAdmin="Admin"/>
    <viewRounds />

    </div>
</template>
<script>
import TitleAdmin from '@/views/constants/titleAdmin.vue'
import viewRounds from '@/components/admin/rounds/index.vue'
export default {
    components:{
        TitleAdmin,
        viewRounds
    }
}
</script>